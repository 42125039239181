import { Component, OnInit, ViewChild, LOCALE_ID, Inject } from '@angular/core';
import * as moment from 'moment';
import { MatTableDataSource } from '@angular/material/table';
import { CubejsService } from '../cubejs/cubejs.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { formatNumber } from '@angular/common';
import { GlobalsService } from '../../globals-services/globals.service';
import { ComplianceCheckerService } from '../../compliance-checker/compliance-checker.service';
import { NewLaunchDialogComponent } from 'app/new-launch-dialog/new-launch-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ExportDataService } from '../services/export-data.service';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';

@Component({
    selector: 'app-dashboard-factory',
    templateUrl: './dashboard-factory.component.html',
    styleUrls: ['./dashboard-factory.component.scss']
})
export class DashboardFactoryComponent implements OnInit {
    @ViewChild('unverifiedMatPaginator') unverifiedMatPaginator: MatPaginator;
    @ViewChild('verifiedMatPaginator') verifiedMatPaginator: MatPaginator;
    @ViewChild('basicMatPaginator') basicMatPaginator: MatPaginator;
    @ViewChild('unverifiedMatSort') unverifiedMatSort: MatSort;
    @ViewChild('verifiedMatSort') verifiedMatSort: MatSort;
    @ViewChild('basicMatSort') basicMatSort: MatSort;



    constructor(private cubejsService: CubejsService,
        private globalsService: GlobalsService,
        private complianceService: ComplianceCheckerService,
        @Inject(LOCALE_ID) public locale: string,
        private dialog: MatDialog, private exportService: ExportDataService) {
    }

    errorList = [];

    latestFormulas = [];
    formulaFilters = [];
    //input parameters
    startDate = moment().subtract(5, 'months').startOf('month').format('YYYY-MM-DD');
    endDate = moment().endOf('month').format('YYYY-MM-DD');
    complianceName: string;
    measure = "Formulas.brand_compliant_pct"
    organizationId: string; //must be a string
    // isLoadingUnverifiedChemicals = true;
    // isLoadingVerifiedChemicals = true;
    // isLoadingBasicChemicals = true;
    // isLoadingOverallPerformance = true;
    isLoading = false;
    tabIndex: number = 0;

    unverifiedTableColumns = ["factory", "name", "count", "consumption", "manufacturer", "source", "remediation", "deadline", "usage", "cap_status"]
    verifiedTableColumns = ["factory", "name", "count", "consumption", "manufacturer", "source", "remediation", "deadline", "usage", "cap_status"]
    basicTableColumns = ["factory", "name", "count", "consumption", "manufacturer", "source", "remediation", "deadline", "usage", "cap_status"]
    //output parameters
    connectedBrands: Number
    distinctFormulaCount: Number
    timeSeriesPerformance: Array<Object>
    unverifiedCapFormulaList = new MatTableDataSource<any>()
    verifiedCapFormulaList = new MatTableDataSource<any>()
    basicCapFormulaList = new MatTableDataSource<any>()

    //chart configs
    view;

    isCubejsConnected: boolean = true;//it is connected to cubejs by default
    appVersionKey = "appVersion";
    latestDialogVersion = "3.4.3"

    myYAxisTickFormatting(val) {
        return val + '%';
    }

    onResize(event) {
        this.view = [(event.target.innerWidth / 4) + 80, (event.target.innerHeight / 7) * 2 - 14];
    }
    getFontSize = (textLength) => {
        const baseSize = 1.8
        if (textLength >= baseSize) {
            textLength = baseSize - 0.2
        }
        const fontSize = baseSize - textLength
        return `${fontSize}vw`
    }
    ngOnInit(): void {
        // let el = document.getElementById('complianceName')
        // el.style.fontSize = this.getFontSize(el.textContent.length);
        //Old
        localStorage.removeItem('version')
        localStorage.removeItem('version1')

        let localStorageDialogVersion = localStorage.getItem(this.appVersionKey);
        let sessionStorageDialogVersion = sessionStorage.getItem(this.appVersionKey);

        /*
        if ( (GlobalsService.isSupplierOfAldiHofer ||GlobalsService.isSupplierOfAldiEinkauf ) &&
            (sessionStorageDialogVersion == undefined || sessionStorageDialogVersion == null || sessionStorageDialogVersion != this.latestDialogVersion)
            && (localStorageDialogVersion == undefined || localStorageDialogVersion == null || localStorageDialogVersion != this.latestDialogVersion)) {
            //show Dialog
            this.showNewLaunchInfoDialog();
        }*/

        if ((sessionStorageDialogVersion == undefined || sessionStorageDialogVersion == null || sessionStorageDialogVersion != this.latestDialogVersion)
            && (localStorageDialogVersion == undefined || localStorageDialogVersion == null || localStorageDialogVersion != this.latestDialogVersion)) {
            //show Dialog
            this.showNewLaunchInfoDialog();
        }

        this.getComplianceName()
        this.organizationId = this.globalsService.profile["id"].toString();
        this.getBrandCount()
        this.getDistinctFormulaCount()
        this.getTimeSeriesPerformance()
        this.onTabChanged()

    }

    changeKey(originalKey: string, newKey: string, arr: Array<Object>) {
        var newArr = [];
        for (var i = 0; i < arr.length; i++) {
            var obj = arr[i];
            obj[newKey] = obj[originalKey];
            delete (obj[originalKey]);
            newArr.push(obj);
        }
        return newArr;
    }
    ShowErrorMessage(msg) {
        this.isLoading = false;
        let customMSg = false;
        if (msg.includes("No id found in Security Context")) {
            msg = 'No factories are connected to this account, so no data can be displayed.\n Ask your factories to connect to you to see their data.',
                customMSg = true;
        }
        if (msg.includes("Network request failed")) {
            this.isCubejsConnected = false;
            throw new Error("CubeJS is not reachable!")
            // return false;
        }
        if (!this.errorList.includes(msg)) {
            this.errorList.push(msg);
            this.dialog.open(MessageDialogComponent, {
                hasBackdrop: true,
                data: {
                    message: msg,
                    hasLink: customMSg ? true : false,
                    goToLink: customMSg ? "How to share inventories" : "",
                    URL: customMSg ? "https://cloud.goblu.net/s/4LXyFHrE86Tzx2A" : ""
                },
            });
            // alert(msg);
        }
    }

    async getFormulasFirst(commodities: boolean) {
        let query = {
            "dimensions": [
                "Formulas.organization_id",
                "Formulas.chemical_identifier"
            ],
            "timeDimensions": [
                {
                    "dimension": "Formulas.scan_date"
                }
            ],
            "order": [
                [
                    "Formulas.chemical_identifier",
                    "asc"
                ]
            ],
            "filters": [
                {
                    "member": "Formulas.scan_date",
                    "operator": "inDateRange",
                    "values": [
                        this.startDate,
                        this.endDate
                    ]
                },
                {
                    "member": "Formulas.basic_chemical",
                    "operator": "equals",
                    "values": commodities ? ['0', '1'] : ['0']
                }
            ],
            "measures": [
                "Formulas.latest_formula_in_range"
            ]
        };
        try {
            const data = await this.cubejsService.getCubeJSData(query, null, null, true, commodities ? true : false)
                let tempData = data['loadResponse']['results'][0]['data'];
                tempData = this.changeKey("Formulas.latest_formula_in_range", "latest_formula", tempData);

                this.latestFormulas = tempData.map(function (el) { return el.latest_formula; });
                for (let obj of this.latestFormulas) {
                    this.formulaFilters.push(obj.toString());
                }
        }
        catch (error) {
            console.error(error);
        }

    }

    getComplianceName() {
        const query = {
            "dimensions": [
                "ComplianceName.compliance_name"
            ],
            "timeDimensions": [],
            "order": {},
            "measures": [],
            "filters": []
        }

        this.cubejsService.getCubeJSData(query, null, null, true, false).then(data => {
            data = data['loadResponse']['results'][0]['data'];
            this.complianceName = data[0]['ComplianceName.compliance_name']
        })
    }

    getBrandCount() {
        const query = {
            "measures": [
                "ActualConnections.total_count"
            ],
            "timeDimensions": [],
            "order": {},
            "dimensions": [],
            "filters": []
        }

        this.cubejsService.getCubeJSData(query, null, null, true, false).then(data => {
            data = data['loadResponse']['results'][0]['data'];
            this.connectedBrands = data[0]['ActualConnections.total_count']
        })
            .catch(error => {
                this.ShowErrorMessage(error.message);
            })
    }

    getDistinctFormulaCount() {
        const query = {
            "measures": [
                "Formulas.formula_distinct_count"
            ],
            "timeDimensions": [
                {
                    "dimension": "Formulas.scan_date"
                }
            ],
            "order": {},
            "dimensions": [],
            "filters": [
                {
                    "member": "Formulas.scan_date",
                    "operator": "inDateRange",
                    "values": [
                        this.startDate,
                        this.endDate
                    ]
                },
                {
                    "member": "Factories.organization_id",
                    "operator": "equals",
                    "values": [
                        this.organizationId
                    ]
                }
            ]
        }


        this.cubejsService.getCubeJSData(query, null, null, true, false).then(data => {
            data = data['loadResponse']['results'][0]['data'];
            this.distinctFormulaCount = data[0]['Formulas.formula_distinct_count']
        })
            .catch(error => {
                this.ShowErrorMessage(error.message);
            })

    }

    getTimeSeriesPerformance() {
        this.isLoading = true;
        const query = {
            "measures": [
                "Formulas.brand_compliant_pct"
            ],
            "timeDimensions": [
                {
                    "dimension": "Formulas.scan_date",
                    "granularity": "month",
                    "dateRange": [
                        this.startDate,
                        this.endDate
                    ]
                }
            ],
            "order": {},
            "dimensions": [
                "Formulas.year_month_scan_date"
            ]
        }

        this.cubejsService.getCubeJSData(query, null, null, true, false)
            .then(data => {
                this.timeSeriesPerformance = data['loadResponse']['results'][0]['data'];
                this.timeSeriesPerformance.forEach(el => el["Formulas.brand_compliant_pct"] = formatNumber(el["Formulas.brand_compliant_pct"], this.locale, '2.1-2'))
                // this.isLoadingOverallPerformance = false;
                this.isLoading = false;
            })
            .then(() => this.timeSeriesPerformance = this.changeKey("Formulas.year_month_scan_date", "name", this.timeSeriesPerformance))
            .then(() => this.timeSeriesPerformance = this.changeKey("Formulas.brand_compliant_pct", "value", this.timeSeriesPerformance))
            .catch(error => {
                this.ShowErrorMessage(error.message);
            })
    }

    selectedTabChanged($event) {
        this.tabIndex = $event.index;
        this.onTabChanged()
    }
    async onTabChanged() {
        // this.isLoading = true;
        switch (this.tabIndex) {
            case 0: {
                try {
                    await this.getFormulasFirst(false);
                } catch (error) {
                    console.error(error);
                }
                try {
                    await this.getUnverifiedCAPData();
                } catch (error) {
                    console.error(error);
                }
                break;
            }
            case 1: {
                await this.getFormulasFirst(false);
                await this.getVerifiedCAPData();
                break;
            }
            case 2: {
                try {
                    await this.getFormulasFirst(true);
                } catch (error) {
                    console.error(error);
                }
                try {
                    await this.getBasicCAPData();
                } catch (error) {
                    console.error(error);
                }
                break;
            }
        }
    }

    async getUnverifiedCAPData() {
        this.isLoading = true;
        const query = {
            "measures": [
                "Formulas.total_count",
                "Formulas.total_consumption"
            ],
            "timeDimensions": [
                {
                    "dimension": "Formulas.scan_date"
                }
            ],
            "order": {
                "Formulas.total_count": "desc"
            },
            "segments": [
                "Formulas.non_compliant_cr",
            ],
            "dimensions": [
                "Formulas.organization_name",
                "Formulas.chemical_name",
                "Formulas.chemical_manufacturer",
                "FormulaUser.deadline",
                "FormulaUser.remediation",
                "Formulas.factory_usage",
                "Formulas._type",
                "Formulas.verification_source",
                "Formulas.cap_status"
            ],
            "filters": [
                {
                    "and": [
                        {

                            "member": "Formulas.scan_date",
                            "operator": "inDateRange",
                            "values": [
                                this.startDate,
                                this.endDate
                            ]

                        },
                        {
                            "or": this.formulaFilters.length == 0 ? [] : [{
                                "member": 'Formulas.id',
                                "operator": 'equals',
                                "values": this.formulaFilters
                            }]
                        }
                    ]
                }
            ]
        }
        try {
        const data = await this.cubejsService.getCubeJSData(query, null, null, true, false)
                let result = data['loadResponse']['results'][0]['data'];
                result = this.changeKey("Formulas.organization_name", "factory", result);
                result = this.changeKey("Formulas.chemical_name", "name", result);
                result = this.changeKey("Formulas.total_count", "count", result);
                result = this.changeKey("Formulas.total_consumption", "consumption", result);
                result = this.changeKey("Formulas.chemical_manufacturer", "manufacturer", result);
                result = this.changeKey("FormulaUser.remediation", "remediation", result);
                result = this.changeKey("FormulaUser.deadline", "deadline", result);
                result = this.changeKey("Formulas.factory_usage", "usage", result);
                result = this.changeKey("Formulas._type", "type", result);
                result = this.changeKey("Formulas.verification_source", "source", result);
                result = this.changeKey("Formulas.cap_status", "cap_status", result);
                this.unverifiedCapFormulaList.data = result;
                // this.isLoadingUnverifiedChemicals = false;
                this.isLoading = false;
                this.unverifiedCapFormulaList.paginator = this.unverifiedMatPaginator;
                this.unverifiedCapFormulaList.sort = this.unverifiedMatSort;
            }
            catch(error) {
                this.ShowErrorMessage(error.message);
            }
    }

    async getVerifiedCAPData() {
        this.isLoading = true;
        const query = {
            "measures": [
                "Formulas.total_count",
                "Formulas.total_consumption"
            ],
            "timeDimensions": [
                {
                    "dimension": "Formulas.scan_date"
                }
            ],
            "segments": [
                "Formulas.compliant_cr",
            ],
            "order": {
                "Formulas.total_count": "desc"
            },
            "dimensions": [
                "Formulas.organization_name",
                "Formulas.chemical_name",
                "Formulas.chemical_manufacturer",
                "FormulaUser.deadline",
                "FormulaUser.remediation",
                "Formulas.factory_usage",
                "Formulas._type",
                "Formulas.verification_source",
                "Formulas.cap_status"
            ],
            "filters": [
                {
                    "and": [
                        {

                            "member": "Formulas.scan_date",
                            "operator": "inDateRange",
                            "values": [
                                this.startDate,
                                this.endDate
                            ]

                        },
                        {
                            "or": this.formulaFilters.length == 0 ? [] : [{
                                "member": 'Formulas.id',
                                "operator": 'equals',
                                "values": this.formulaFilters
                            }]
                        }
                    ]
                }
            ]
        }

        try {
            const data = await this.cubejsService.getCubeJSData(query, null, null, true, false)
                let result = data['loadResponse']['results'][0]['data'];
                result = this.changeKey("Formulas.organization_name", "factory", result);
                result = this.changeKey("Formulas.chemical_name", "name", result);
                result = this.changeKey("Formulas.total_count", "count", result);
                result = this.changeKey("Formulas.total_consumption", "consumption", result);
                result = this.changeKey("Formulas.chemical_manufacturer", "manufacturer", result);
                result = this.changeKey("FormulaUser.remediation", "remediation", result);
                result = this.changeKey("FormulaUser.deadline", "deadline", result);
                result = this.changeKey("Formulas.factory_usage", "usage", result);
                result = this.changeKey("Formulas._type", "type", result);
                result = this.changeKey("Formulas.verification_source", "source", result);
                result = this.changeKey("Formulas.cap_status", "cap_status", result);
                this.verifiedCapFormulaList.data = result;
                // this.isLoadingVerifiedChemicals = false;
                this.isLoading = false;
                this.verifiedCapFormulaList.paginator = this.verifiedMatPaginator;
                this.verifiedCapFormulaList.sort = this.verifiedMatSort;
            }
            catch(error) {
                this.ShowErrorMessage(error.message);
            }
    }

    async getBasicCAPData() {
        this.isLoading = true;
        const query = {
            "measures": [
                "Formulas.total_count",
                "Formulas.total_consumption"
            ],
            "timeDimensions": [
                {
                    "dimension": "Formulas.scan_date"
                }
            ],
            "order": {
                "Formulas.total_count": "desc"
            },
            "segments": [
                "Formulas.basic_chemical_cr",
            ],
            "dimensions": [
                "Formulas.organization_name",
                "Formulas.chemical_name",
                "Formulas.chemical_manufacturer",
                "FormulaUser.deadline",
                "FormulaUser.remediation",
                "Formulas.factory_usage",
                "Formulas._type",
                "Formulas.verification_source",
                "Formulas.cap_status"
            ],
            "filters": [
                {
                    "and": [
                        {

                            "member": "Formulas.scan_date",
                            "operator": "inDateRange",
                            "values": [
                                this.startDate,
                                this.endDate
                            ]

                        },
                        {
                            "or": this.formulaFilters.length == 0 ? [] : [{
                                "member": 'Formulas.id',
                                "operator": 'equals',
                                "values": this.formulaFilters
                            }]
                        }
                    ]
                }
            ]
        }
        try {
        const data = await this.cubejsService.getCubeJSData(query, null, null, true, true)
                let result = data['loadResponse']['results'][0]['data'];
                result = this.changeKey("Formulas.organization_name", "factory", result);
                result = this.changeKey("Formulas.chemical_name", "name", result);
                result = this.changeKey("Formulas.total_count", "count", result);
                result = this.changeKey("Formulas.total_consumption", "consumption", result);
                result = this.changeKey("Formulas.chemical_manufacturer", "manufacturer", result);
                result = this.changeKey("FormulaUser.remediation", "remediation", result);
                result = this.changeKey("FormulaUser.deadline", "deadline", result);
                result = this.changeKey("Formulas.factory_usage", "usage", result);
                result = this.changeKey("Formulas._type", "type", result);
                result = this.changeKey("Formulas.verification_source", "source", result);
                result = this.changeKey("Formulas.cap_status", "cap_status", result);
                this.basicCapFormulaList.data = result;
                // this.isLoadingBasicChemicals = false;
                this.isLoading = false;
                this.basicCapFormulaList.paginator = this.basicMatPaginator;
                this.basicCapFormulaList.sort = this.basicMatSort;
        }
        catch(error) {
                this.ShowErrorMessage(error.message);
        }
    }


    showNewLaunchInfoDialog() {
        const dialogRef = this.dialog.open(NewLaunchDialogComponent, {
            width: '55%',
            //   height: '30%'
        });

        dialogRef.afterClosed().subscribe(response => {

            if (response) {
                localStorage.setItem(this.appVersionKey, this.latestDialogVersion)
            } else {
                sessionStorage.setItem(this.appVersionKey, this.latestDialogVersion)
            }


        });
    }

    exportData(data: any, fileName: string): void {
        if (typeof (data) === 'number') {
            switch (this.tabIndex) {
                case 0: {
                    this.exportService.exportAsExcelFile(this.unverifiedCapFormulaList.data, "UnverifiedChemicals");
                    break;
                }
                case 1: {
                    this.exportService.exportAsExcelFile(this.verifiedCapFormulaList.data, "VerifiedChemicals");
                    break;
                }
                case 2: {
                    this.exportService.exportAsExcelFile(this.basicCapFormulaList.data, "CommodityChemicals");
                    break;
                }
            }
        }
        else {
            this.exportService.exportAsExcelFile(data, fileName);
        }

    }
}
