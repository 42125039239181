 <div class="flex flex-col flex-auto min-w-0">
   <div class="flex-auto p-2 sm:p-10">
        <div class="content header">
            <div class="flex items-center justify-between w-full ">
                <h2  class="headers text-3xl font-semibold tracking-tight leading-8 flex-auto"
                    i18n="Inventories Overview Component header">
                    {{this.organization_name + ' '+ ('BRAND_MONTHLY_REPORT.PAGE_HEADER' | translate) }}
                </h2>
            </div>           

            <div>
                <h2 class="headers page-sub-header" i18n="Inventories Overview Component sub header">
                    {{'BRAND_MONTHLY_REPORT.PAGE_SUB_HEADER' | translate }}
                </h2>
            </div>
        </div>

        <div class="content mt-4">
            <div class="flex flex-row">
                <mat-form-field>
                <mat-label>Month and Year</mat-label>
                <input matInput [matDatepicker]="dp" [formControl]="date">
                <mat-hint>MM/YYYY</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
                <mat-datepicker #dp
                        startView="multi-year"
                        (monthSelected)="setMonthAndYear($event, dp)"
                        panelClass="example-month-picker">
                </mat-datepicker>
                </mat-form-field>
            
                <div class='ml-4 mt-8'>
                    <button class="btn btn-primary" style="color:white" (click)="exportData()">
                        {{ "BRAND_MONTHLY_REPORT.PAGE_HEADER" | translate }}
                    <mat-icon style="color:white; vertical-align: middle" [svgIcon]="'mat_outline:file_download'"></mat-icon>
                    </button>
                </div>
            </div>  
            <mat-tab-group mat-stretch-tabs mat-stretch-tabs (selectedTabChange)="selectedTabChanged($event)">
                <mat-tab label="Compliance Overview">
                    <mat-card class="h-fit">
                        <mat-card-content class="h-5/6">
                            <div class="h-full scroll">
                                <table mat-table [dataSource]="monthlyDataListCA" #candADataMatSort="matSort" matSort>

                                    <ng-container matColumnDef="factory_name">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Factory Name</th>
                                        <td mat-cell *matCellDef="let formula">
                                            {{formula.factory_name}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="country_name">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Country Name</th>
                                        <td mat-cell *matCellDef="let formula">
                                            {{formula.country_name}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="month_of_inventory">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Month of inventory</th>
                                        <td mat-cell *matCellDef="let formula">
                                            {{formula.month_of_inventory}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="formula_count">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Formula Count</th>
                                        <td mat-cell *matCellDef="let formula">
                                            {{formula.formula_count}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="compliant_formula_count">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Compliant Formula Count</th>
                                        <td mat-cell *matCellDef="let formula"
                                            >
                                            {{formula.compliant_formula_count}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="count_compliance_pct">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Count Compliance Pct</th>
                                        <td mat-cell *matCellDef="let formula"
                                            >
                                            {{formula.count_compliance_pct}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="brand_formula_count">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Brand Formula Count</th>
                                        <td mat-cell *matCellDef="let formula">
                                            {{formula.brand_formula_count }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="compliant_brand_formula_count">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Compliant Brand Formula Count</th>
                                        <td mat-cell *matCellDef="let formula">
                                            {{formula.compliant_brand_formula_count }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="brand_count_compliance_pct">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Brand Count Compliance Pct</th>
                                        <td mat-cell *matCellDef="let formula">
                                            {{formula.brand_count_compliance_pct}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="consumption">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Consumption</th>
                                        <td mat-cell *matCellDef="let formula">
                                            {{formula.consumption }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="compliant_quantity">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Compliant Quantity</th>
                                        <td mat-cell *matCellDef="let formula">
                                            {{formula.compliant_quantity}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="consumption_compliance_pct">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Consumption Compliance Pct</th>
                                        <td mat-cell *matCellDef="let formula"
                                            >
                                            {{formula.consumption_compliance_pct}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="brand_consumption">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Brand Consumption</th>
                                        <td mat-cell *matCellDef="let formula">
                                            {{formula.brand_consumption}}
                                    </ng-container>
                                            
                                    <ng-container matColumnDef="compliant_brand_consumption">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Compliant Brand Consumption</th>
                                        <td mat-cell *matCellDef="let formula"
                                            >
                                            {{formula.compliant_brand_consumption}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="brand_consumption_compliance_pct">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Brand Consumption Compliance Pct</th>
                                        <td mat-cell *matCellDef="let formula"
                                            >
                                            {{formula.brand_consumption_compliance_pct}}
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="c_a_data; sticky: true;"></tr>
                                    <tr mat-row *matRowDef="let row; columns: c_a_data;"></tr>

                                </table>
                                <div *ngIf="hasNotData" style="text-align:center;">
                                    No data is available
                                </div>
                                <loading-spinner *ngIf="isLoadingc_a_DataInfo"></loading-spinner> 
                            </div>                                 
                            <mat-paginator #cADataPaginator="matPaginator" [pageSizeOptions]="pageSizeOptions"
                            showFirstLastButtons></mat-paginator>  
                        </mat-card-content>
                    </mat-card>
                </mat-tab>
                <mat-tab label="Non Compliance Overview">
                    <mat-card class="h-fit">
                        <mat-card-subtitle>Unverified Chemicals by Factory</mat-card-subtitle>
                        <mat-card-content class="h-5/6">
                            <div class="h-full scroll">
                                <table mat-table [dataSource]="monthlyDataListCA_nonCompliantFactory" #candADataMatSort_nonCompliantFactory="matSort" matSort>

                                    <ng-container matColumnDef="factory_name">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Factory Name</th>
                                        <td mat-cell *matCellDef="let formula">
                                            {{formula.factory_name}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="country_name">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Country Name</th>
                                        <td mat-cell *matCellDef="let formula">
                                            {{formula.country_name}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="month_of_inventory">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Month of inventory</th>
                                        <td mat-cell *matCellDef="let formula">
                                            {{formula.month_of_inventory | date:"yyyy-MM"}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="formula_count">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Formula Count</th>
                                        <td mat-cell *matCellDef="let formula">
                                            {{formula.formula_count}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="non_compliant_formula_count">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Non Compliant Formula Count</th>
                                        <td mat-cell *matCellDef="let formula"
                                            >
                                            {{formula.non_compliant_formula_count}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="count_non_compliance_pct">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Count Non Compliance Pct</th>
                                        <td mat-cell *matCellDef="let formula"
                                            >
                                            {{formula.count_non_compliance_pct }}
                                        </td>
                                    </ng-container>


                                    <ng-container matColumnDef="brand_formula_count">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Brand Formula Count</th>
                                        <td mat-cell *matCellDef="let formula">
                                            {{formula.brand_formula_count }}
                                        </td>
                                    </ng-container>


                                    <ng-container matColumnDef="non_compliant_brand_formula_count">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Non Compliant Brand Formula Count</th>
                                        <td mat-cell *matCellDef="let formula">
                                            {{formula.non_compliant_brand_formula_count }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="brand_count_non_compliance_pct">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Non Compliant Brand Formula Count Pct</th>
                                        <td mat-cell *matCellDef="let formula">
                                            {{formula.brand_count_non_compliance_pct  }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="consumption">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Consumption</th>
                                        <td mat-cell *matCellDef="let formula">
                                            {{formula.consumption}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="non_compliant_quantity">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Non Compliant Quantity</th>
                                        <td mat-cell *matCellDef="let formula">
                                            {{formula.non_compliant_quantity }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="consumption_non_compliance_pct">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Consumption Non Compliance Pct</th>
                                        <td mat-cell *matCellDef="let formula"
                                            >
                                            {{formula.consumption_non_compliance_pct}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="brand_consumption">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Brand Consumption</th>
                                        <td mat-cell *matCellDef="let formula">
                                            {{formula.brand_consumption}}
                                    </ng-container>

                                    <ng-container matColumnDef="non_compliant_brand_consumption">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Non Compliant Brand Consumption</th>
                                        <td mat-cell *matCellDef="let formula">
                                            {{formula.non_compliant_brand_consumption}}
                                    </ng-container>

                                    <ng-container matColumnDef="brand_consumption_non_compliance_pct">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Brand Consumption Non Compliance Pct</th>
                                        <td mat-cell *matCellDef="let formula"
                                            >
                                            {{formula.brand_consumption_non_compliance_pct}}
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="c_a_data_nonCompliantFactory; sticky: true;"></tr>
                                    <tr mat-row *matRowDef="let row; columns: c_a_data_nonCompliantFactory;"></tr>

                                </table>
                                <div *ngIf="hasNotDataFactory" style="text-align:center;">
                                    No data is available
                                </div>                        
                                <loading-spinner *ngIf="isLoadingc_a_DataInfo_nonCompliantFactory"></loading-spinner> 
                            </div>                                         
                            <mat-paginator #cADataPaginator_nonCompliantFactory="matPaginator" [pageSizeOptions]="pageSizeOptions"
                            showFirstLastButtons></mat-paginator> 
                        </mat-card-content>
                    </mat-card> 
                    <mat-card class="h-fit">
                        <mat-card-subtitle>Unverified Chemicals by Country</mat-card-subtitle>
                        <mat-card-content class="h-5/6">
                            <div class="h-full scroll">
                                <table mat-table [dataSource]="monthlyDataListCA_nonCompliant" #candADataMatSort_nonCompliant="matSort" matSort>

                                    <ng-container matColumnDef="country_name">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Country Name</th>
                                        <td mat-cell *matCellDef="let formula">
                                            {{formula.country_name}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="month_of_inventory">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Month of inventory</th>
                                        <td mat-cell *matCellDef="let formula">
                                            {{formula.month_of_inventory | date:"yyyy-MM"}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="formula_count">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Formula Count</th>
                                        <td mat-cell *matCellDef="let formula">
                                            {{formula.formula_count}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="non_compliant_formula_count">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Non Compliant Formula Count</th>
                                        <td mat-cell *matCellDef="let formula"
                                            >
                                            {{formula.non_compliant_formula_count}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="count_non_compliance_pct">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Count Non Compliance Pct</th>
                                        <td mat-cell *matCellDef="let formula"
                                            >
                                            {{formula.count_non_compliance_pct }}
                                        </td>
                                    </ng-container>


                                    <ng-container matColumnDef="brand_formula_count">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Brand Formula Count</th>
                                        <td mat-cell *matCellDef="let formula">
                                            {{formula.brand_formula_count }}
                                        </td>
                                    </ng-container>


                                    <ng-container matColumnDef="non_compliant_brand_formula_count">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Non Compliant Brand Formula Count</th>
                                        <td mat-cell *matCellDef="let formula">
                                            {{formula.non_compliant_brand_formula_count }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="brand_count_non_compliance_pct">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Non Compliant Brand Formula Count Pct</th>
                                        <td mat-cell *matCellDef="let formula">
                                            {{formula.brand_count_non_compliance_pct  }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="consumption">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Consumption</th>
                                        <td mat-cell *matCellDef="let formula">
                                            {{formula.consumption }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="non_compliant_quantity">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Non Compliant Quantity</th>
                                        <td mat-cell *matCellDef="let formula">
                                            {{formula.non_compliant_quantity  }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="consumption_non_compliance_pct">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Consumption Non Compliance Pct</th>
                                        <td mat-cell *matCellDef="let formula"
                                            >
                                            {{formula.consumption_non_compliance_pct  }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="brand_consumption">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Brand Consumption</th>
                                        <td mat-cell *matCellDef="let formula">
                                            {{formula.brand_consumption }}
                                    </ng-container>

                                    <ng-container matColumnDef="non_compliant_brand_consumption">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Non Compliant Brand Consumption</th>
                                        <td mat-cell *matCellDef="let formula">
                                            {{formula.non_compliant_brand_consumption }}
                                    </ng-container>

                                    <ng-container matColumnDef="brand_consumption_non_compliance_pct">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Brand Consumption Non Compliance Pct</th>
                                        <td mat-cell *matCellDef="let formula"
                                            >
                                            {{formula.brand_consumption_non_compliance_pct }}
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="c_a_data_nonCompliant; sticky: true;"></tr>
                                    <tr mat-row *matRowDef="let row; columns: c_a_data_nonCompliant;"></tr>

                                </table>
                                <div *ngIf="hasNotData" style="text-align:center;">
                                    No data is available
                                </div>                        
                                <loading-spinner *ngIf="isLoadingc_a_DataInfo_nonCompliant"></loading-spinner> 
                            </div>                                         
                            <mat-paginator #cADataPaginator_nonCompliant="matPaginator" [pageSizeOptions]="pageSizeOptions"
                            showFirstLastButtons></mat-paginator>  
                        </mat-card-content>
                    </mat-card> 
                    <mat-card class="h-fit">
                        <mat-card-subtitle>Unverified Chemicals Globally</mat-card-subtitle>
                        <mat-card-content class="h-5/6">
                            <div class="h-full scroll">
                                <table mat-table [dataSource]="monthlyDataListCA_nonCompliant_Globally" #candADataMatSort_nonCompliant_Globally="matSort" matSort>

                                    <ng-container matColumnDef="month_of_inventory">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Month of inventory</th>
                                        <td mat-cell *matCellDef="let formula">
                                            {{formula.month_of_inventory | date:"yyyy-MM"}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="formula_total_count">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Formula Count</th>
                                        <td mat-cell *matCellDef="let formula">
                                            {{formula.formula_total_count}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="non_compliant_formula_count">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Non Compliant Formula Count</th>
                                        <td mat-cell *matCellDef="let formula"
                                            >
                                            {{formula.non_compliant_formula_count}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="count_non_compliance_pct">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Count Non Compliance Pct</th>
                                        <td mat-cell *matCellDef="let formula"
                                            >
                                            {{formula.count_non_compliance_pct }}
                                        </td>
                                    </ng-container>


                                    <ng-container matColumnDef="brand_formula_total_count">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Brand Formula Count</th>
                                        <td mat-cell *matCellDef="let formula">
                                            {{formula.brand_formula_total_count }}
                                        </td>
                                    </ng-container>


                                    <ng-container matColumnDef="non_compliant_brand_formula_count">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Non Compliant Brand Formula Count</th>
                                        <td mat-cell *matCellDef="let formula">
                                            {{formula.non_compliant_brand_formula_count }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="brand_count_non_compliance_pct">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Non Compliant Brand Formula Count Pct</th>
                                        <td mat-cell *matCellDef="let formula">
                                            {{formula.brand_count_non_compliance_pct  }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="consumption_sum">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Consumption</th>
                                        <td mat-cell *matCellDef="let formula">
                                            {{formula.consumption_sum}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="non_compliant_quantity">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Non Compliant Quantity</th>
                                        <td mat-cell *matCellDef="let formula">
                                            {{formula.non_compliant_quantity  }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="consumption_non_compliance_pct">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Consumption Non Compliance Pct</th>
                                        <td mat-cell *matCellDef="let formula"
                                            >
                                            {{formula.consumption_non_compliance_pct  }}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="brand_consumption_sum">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Brand Consumption</th>
                                        <td mat-cell *matCellDef="let formula">
                                            {{formula.brand_consumption_sum }}
                                    </ng-container>

                                    <ng-container matColumnDef="non_compliant_brand_consumption">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Non Compliant Brand Consumption</th>
                                        <td mat-cell *matCellDef="let formula">
                                            {{formula.non_compliant_brand_consumption }}
                                    </ng-container>

                                    <ng-container matColumnDef="brand_consumption_non_compliance_pct">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Brand Consumption Non Compliance Pct</th>
                                        <td mat-cell *matCellDef="let formula"
                                            >
                                            {{formula.brand_consumption_non_compliance_pct }}
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="c_a_data_nonCompliant_Globally; sticky: true;"></tr>
                                    <tr mat-row *matRowDef="let row; columns: c_a_data_nonCompliant_Globally;"></tr>

                                </table>
                                <div *ngIf="hasNotDataGlobally" style="text-align:center;">
                                    No data is available
                                </div>                        
                                <loading-spinner *ngIf="isLoadingc_a_DataInfo_nonCompliant_Globally"></loading-spinner> 
                            </div>                                         
                            <mat-paginator #cADataPaginator_nonCompliant_Globally="matPaginator" [pageSizeOptions]="pageSizeOptions"
                            showFirstLastButtons></mat-paginator>   
                        </mat-card-content>
                    </mat-card>
                    <mat-card class="h-fit">
                        <mat-card-subtitle>Unverified Chemical List</mat-card-subtitle>
                        <mat-card-content class="h-5/6">
                            <div class="h-full scroll">
                                <table mat-table [dataSource]="monthlyDataListCA_nonCompliant_ChemicalsList" #candADataMatSort_nonCompliant_ChemicalsList="matSort" matSort>

                                    <ng-container matColumnDef="factory_name">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Factory Name</th>
                                        <td mat-cell *matCellDef="let formula">
                                            {{formula.factory_name}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="chemical_name">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chemical Name</th>
                                        <td mat-cell *matCellDef="let formula">
                                            {{formula.chemical_name}}
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="manufacturer">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chemical Manufacturer</th>
                                        <td mat-cell *matCellDef="let formula">
                                            {{formula.manufacturer}}
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="c_a_data_nonCompliant_ChemicalList; sticky: true;"></tr>
                                    <tr mat-row *matRowDef="let row; columns: c_a_data_nonCompliant_ChemicalList;"></tr>

                                </table>
                                <div *ngIf="hasNotDataChemicalList" style="text-align:center;">
                                    No data is available
                                </div>
                                <loading-spinner *ngIf="isLoadingc_a_DataInfo_nonCompliant_ChemicalsList"></loading-spinner>                                          
                            </div>
                            <mat-paginator #cADataPaginator_nonCompliant_ChemicalsList="matPaginator" [pageSizeOptions]="pageSizeOptions"
                            showFirstLastButtons></mat-paginator>  
                        </mat-card-content>
                    </mat-card> 
                </mat-tab>
                <mat-tab label="Complete List of Chemicals">
                    <mat-card class="h-fit">
                        <mat-card-content class="h-5/6">
                            <div class="h-full scroll">
                            <table mat-table [dataSource]="monthlyDataListCAFormula" #cAFormulaDataMatSort="matSort" matSort>

                                <ng-container matColumnDef="factory_name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Factory Name</th>
                                    <td mat-cell *matCellDef="let formula">
                                        {{formula.factory_name}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="chemical_product">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Chemical Product</th>
                                    <td mat-cell *matCellDef="let formula">
                                        {{formula.chemical_product}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="level">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Level</th>
                                    <td mat-cell *matCellDef="let formula">
                                        {{formula.level}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="version">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Version</th>
                                    <td mat-cell *matCellDef="let formula">
                                        {{formula.version}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="compliance_status">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Compliance Status</th>
                                    <td mat-cell *matCellDef="let formula">
                                        {{formula.compliance_status}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="gots">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Gots</th>
                                    <td mat-cell *matCellDef="let formula">
                                        {{formula.gots}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="eco_passport">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Eco Passport</th>
                                    <td mat-cell *matCellDef="let formula">
                                        {{formula.eco_passport}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="bluesign">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Bluesign</th>
                                    <td mat-cell *matCellDef="let formula">
                                        {{formula.bluesign}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="c2c">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>C2C</th>
                                    <td mat-cell *matCellDef="let formula">
                                        {{formula.c2c}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="green_screen">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>GreenScreen</th>
                                    <td mat-cell *matCellDef="let formula">
                                        {{formula.green_screen}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="toxfmd_scivera">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Toxfmd Scivera</th>
                                    <td mat-cell *matCellDef="let formula">
                                        {{formula.toxfmd_scivera}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="test_report">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Test Report</th>
                                    <td mat-cell *matCellDef="let formula">
                                        {{formula.test_report}}
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="c_a_formula; sticky: true;"></tr>
                                <tr mat-row *matRowDef="let row; columns: c_a_formula;"></tr>

                            </table>
                            <div *ngIf="hasNotData" style="text-align:center;">
                                No data is available
                            </div>
                            <loading-spinner *ngIf="isLoadingc_a_FormulaInfo"></loading-spinner>                                          
                            </div>
                            <mat-paginator #cAFormulaPaginator="matPaginator" [pageSizeOptions]="pageSizeOptions"
                            showFirstLastButtons></mat-paginator> 
                        </mat-card-content>
                    </mat-card>                 
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
 </div>
