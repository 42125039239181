import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { factoryTypeNamesArray } from 'app/environmental-data/production-data/factory_type_data';
import { GlobalsService } from 'app/globals-services/globals.service';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductOrderService {












  private cache: { [url: string]: Observable<any> } = {};

  public static dppTemplateSupplierCertificationList: any = [
    { value: 'OEKO-TEX STeP', title: 'OEKO-TEX STeP' },
    { value: 'OEKO-TEX Standard 100', title: 'OEKO-TEX Standard 100' },
    { value: 'Other', title: 'Other' },
    { value: 'Higg FEM 4.0', title: 'Higg FEM 4.0' },
    { value: 'Made in Green', title: 'Made in Green' },
    { value: 'Bluesign', title: 'Bluesign' },
    { value: 'GOTS', title: 'GOTS' },
    { value: 'OCR', title: 'OCR' },
    { value: 'RDS', title: 'RDS' },
    { value: 'StZ', title: 'StZ' }



  ]

  public static dppTemplateSupplierOrgLevelDoc: any = [
    { "value": "OEKO-TEX STeP", "title": "OEKO-TEX STeP" },
    { "value": "OEKO-TEX LEATHER STANDARD", "title": "OEKO-TEX LEATHER STANDARD" },
    { "value": "OEKO-TEX RESPONSIBLE BUSINESS", "title": "OEKO-TEX RESPONSIBLE BUSINESS" },
    { "value": "Higg FEM 4.0", "title": "Higg FEM 4.0" },
    { "value": "Bluesign", "title": "Bluesign" },
    { "value": "GOTS Scope Certificate", "title": "GOTS Scope Certificate" },
    { "value": "Worldwide Responsible Accredited Production (WRAP)", "title": "Worldwide Responsible Accredited Production (WRAP)" },
    { "value": "Business Social Compliance Initiative (BSCI)", "title": "Business Social Compliance Initiative (BSCI)" },
    { "value": "Business Environment Performance Initiative (BEPI)", "title": "Business Environment Performance Initiative (BEPI)" },
    { "value": "Social Audit - Others", "title": "Social Audit - Others" }
  ]

  public static dppTemplateSupplierProductLevelDoc: any = [
    { "value": "Test Report", "title": "Test Report" },
    { "value": "OEKO-TEX STANDARD 100", "title": "OEKO-TEX STANDARD 100" },
    { "value": "OEKO-TEX MADE IN GREEN", "title": "OEKO-TEX MADE IN GREEN" },
    { "value": "OEKO-TEX RESPONSIBLE BUSINESS", "title": "OEKO-TEX RESPONSIBLE BUSINESS" },
    { "value": "Textile Exchange - Content Claim Standard (CCS)", "title": "Textile Exchange - Content Claim Standard (CCS)" },
    { "value": "Cradle to Cradle", "title": "Cradle to Cradle" },
    { "value": "Transaction Certificate", "title": "Transaction Certificate" },
    { "value": "Forest Stewardship Council (FSC)", "title": "Forest Stewardship Council (FSC)" },
    { "value": "World Fair Trade Organization (WFTO)", "title": "World Fair Trade Organization (WFTO)" },
    { "value": "Guarantee System (GS)", "title": "Guarantee System (GS)" },
    // { "value": "Others", "title": "Others" }
  ]
  public static dppTemplateSupplierMaterialFiberLevelDoc: any =
    [
      { "value": "Test Report", "title": "Test Report" },
      { "value": "OEKO-TEX ORGANIC COTTON", "title": "OEKO-TEX ORGANIC COTTON" },
      { "value": "Better Cotton Membership incl. BBCU (Better Cotton Claim Unit)", "title": "Better Cotton Membership incl. BBCU (Better Cotton Claim Unit)" },
      { "value": "Textile Exchange - Recycled Claim Standard (RCS)", "title": "Textile Exchange - Recycled Claim Standard (RCS)" },
      { "value": "Textile Exchange - Global Recycled Standard (GRS)", "title": "Textile Exchange - Global Recycled Standard (GRS)" },
      { "value": "Textile Exchange - Responsible Wool Standard (RWS)", "title": "Textile Exchange - Responsible Wool Standard (RWS)" },
      { "value": "Textile Exchange - Responsible Mohair Standard (RMS)", "title": "Textile Exchange - Responsible Mohair Standard (RMS)" },
      { "value": "Textile Exchange - Responsible Alpaca Standard (RAS)", "title": "Textile Exchange - Responsible Alpaca Standard (RAS)" },
      { "value": "Textile Exchange - Responsible Down Standard (RDS)", "title": "Textile Exchange - Responsible Down Standard (RDS)" },
      { "value": "Textile Exchange - Organic Content Standard (OCS)", "title": "Textile Exchange - Organic Content Standard (OCS)" },
      { "value": "Fairtrade Cotton Certificate", "title": "Fairtrade Cotton Certificate" },
      // { "value": "Others", "title": "Others" }
    ]


  constructor(
    private http: HttpClient,
    private globalService: GlobalsService
  ) { }


  addUpdateProductOrder(res: any) {
    return this.http.post<any[]>(environment.baseHref + '/productOrder/addUpdate', res).pipe(
      catchError(this.globalService.handleError))
  }


  handOverUpdate(res: any) {
    return this.http.post<any[]>(environment.baseHref + '/productOrder/handOverUpdate', res).pipe(
      catchError(this.globalService.handleError))
  }


  searchProductOrderByBrandOrVendor(data: any) {
    return this.http.post<any[]>(environment.baseHref + '/productOrder/searchProductOrderByBrandOrVendor', data).pipe(
      catchError(this.globalService.handleError))
  }

  getMyProductOrderList(res: any) {
    return this.http.post<any[]>(environment.baseHref + '/productOrder/list', res).pipe(
      catchError(this.globalService.handleError))
  }
  getMyProductOrderCount() {
    return this.http.get<any[]>(environment.baseHref + '/productOrder/count').pipe(
      catchError(this.globalService.handleError))
  }


  getVendorProductOrderList(res: any) {
    return this.http.post<any[]>(environment.baseHref + '/productOrder/getVendorProductOrderList', res).pipe(
      catchError(this.globalService.handleError))
  }
  getVendorProductOrdersCount() {
    return this.http.get<any[]>(environment.baseHref + '/productOrder/getVendorProductOrdersCount').pipe(
      catchError(this.globalService.handleError))
  }


  getSupplierProductOrderList(res: any) {
    return this.http.post<any[]>(environment.baseHref + '/productOrder/getSupplierProductOrderList', res).pipe(
      catchError(this.globalService.handleError))
  }
  getSupplierProductOrdersCount() {
    return this.http.get<any[]>(environment.baseHref + '/productOrder/getSupplierProductOrdersCount').pipe(
      catchError(this.globalService.handleError))
  }



  saveColorway(res: any) {
    return this.http.post<any[]>(environment.baseHref + '/productOrder/colorway/addUpdate', res).pipe(
      catchError(this.globalService.handleError))
  }
  getMyLevelOneSuppliers() {
    //return this.http.get<any[]>(environment.baseHref + '/mta/getMyLevelOneSuppliers');
    let url = environment.baseHref + '/mta/getMyLevelOneSuppliers'
    if (this.cache[url]) {
      // Return cached observable if it exists
      return this.cache[url];
    } else {
      // Fetch data from the API and cache the observable
      const observable = this.http.get(url).pipe(
        tap((data) => {
          // Cache the observable
          this.cache[url] = of(data);
        })
      );

      // Cache the observable
      this.cache[url] = observable;

      return observable;
    }

  }

  getProductOrderDetailsById(id: number) {
    return this.http.get<any[]>(environment.baseHref + `/productOrder/get/${id}`).pipe(
      catchError(this.globalService.handleError))
  }

  getColorwayDetails(id: any) {
    return this.http.get<any[]>(environment.baseHref + `/productOrder/colorway/details/${id}`).pipe(
      catchError(this.globalService.handleError))
  }

  productOrderColorwayList(id: any) {
    return this.http.get<any[]>(environment.baseHref + `/productOrder/colorway/list/${id}`).pipe(
      catchError(this.globalService.handleError))
  }

  nominateSupplierToComponent(data: any) {
    return this.http.post<any[]>(environment.baseHref + '/productOrder/colorway/component/nominate', data).pipe(
      catchError(this.globalService.handleError))
  }
  assignSupplierToComponent(data) {
    return this.http.post<any[]>(environment.baseHref + '/productOrder/colorway/component/assign', data).pipe(
      catchError(this.globalService.handleError))
  }

  assignThirdPartyColorwayOrComponentSupplier(data) {
    return this.http.post<any[]>(environment.baseHref + '/productOrder/colorway/component/assign/thirdParty', data).pipe(
      catchError(this.globalService.handleError))
  }

  addUpdateDPPTemplate(res: any) {
    return this.http.post<any[]>(environment.baseHref + '/productOrder/dpp-template/addUpdate', res).pipe(
      catchError(this.globalService.handleError))
  }
  getDppTemplateList(organizationId) {
    return this.http.get<any[]>(environment.baseHref + '/productOrder/dpp-template/list/' + organizationId).pipe(
      catchError(this.globalService.handleError))
  }

  getDppTemplateById(id) {
    return this.http.get<any[]>(environment.baseHref + '/productOrder/dpp-template/get/' + id).pipe(
      catchError(this.globalService.handleError))
  }

  deleteDPPTemplate(arrayOfIds: number[]) {
    return this.http.post<any>(environment.baseHref + '/productOrder/dpp-template/delete', arrayOfIds).pipe(
      catchError(this.globalService.handleError))
  }

  getAssignmentToMe(productOrderId, selectedFactoryType?: number) {
    return this.http.get<any[]>(environment.baseHref + `/productOrder/getAssignmentToMe/${productOrderId}/${selectedFactoryType}`).pipe(
      catchError(this.globalService.handleError))
  }

  getProcessChemicalDetails(isColorWayProcess, componentId) {
    return this.http.get<any[]>(environment.baseHref + '/productOrder/component/process/chemicalData/details/' + isColorWayProcess + '/' + componentId).pipe(
      catchError(this.globalService.handleError))
  }
  deleteSelectedChemicalData(dataTobeDelete: { processesIds: any[]; machineIds: any[]; chemicalIds: any[]; }) {
    return this.http.post<any>(environment.baseHref + '/productOrder/component/process/chemicalData/delete', dataTobeDelete).pipe(
      catchError(this.globalService.handleError))
  }

  addUpdateProcessChemicalData(processData: any) {
    return this.http.post<any[]>(environment.baseHref + '/productOrder/component/process/chemicalData/addUpdate', processData).pipe(
      catchError(this.globalService.handleError))
  }
  getAllMachineList(id: any) {
    return this.http.get<any[]>(environment.baseHref + '/getSupplierMachineList/' + id).pipe(
      catchError(this.globalService.handleError))
  }

  addUpdateSubComponent(component: any) {
    return this.http.post<any[]>(environment.baseHref + '/productOrder/colorway/addUpdateSubComponent', component).pipe(
      catchError(this.globalService.handleError))
  }

  onChemicalApplicationChange(component: any) {
    return this.http.post<any[]>(environment.baseHref + '/component/process/chemicalData/onChemicalApplicationChange', component).pipe(
      catchError(this.globalService.handleError))
  }

  colorWayNominationStatusUpdate(nomination: any) {
    return this.http.post<any[]>(environment.baseHref + '/productOrder/colorway/nomination/statusUpdate', nomination).pipe(
      catchError(this.globalService.handleError))
  }

  componentNominationStatusUpdate(nomination: any) {
    return this.http.post<any[]>(environment.baseHref + '/productOrder/component/nomination/statusUpdate', nomination).pipe(
      catchError(this.globalService.handleError))
  }

  colorWayNominationDelete(nomination: any) {
    return this.http.post<any[]>(environment.baseHref + '/productOrder/colorway/nomination/delete', nomination).pipe(
      catchError(this.globalService.handleError))
  }

  componentNominationDelete(nomination: any) {
    return this.http.post<any[]>(environment.baseHref + '/productOrder/component/nomination/delete', nomination).pipe(
      catchError(this.globalService.handleError))
  }


  getPoHandlerDetailedData(id: any) {
    return this.http.get<any[]>(environment.baseHref + `/productOrder/getPoHandlerDetailedData/${id}`).pipe(
      catchError(this.globalService.handleError))
  }

  updateOnUnitQuantityChange(componentAssingedToMeDetails) {
    return this.http.post<any[]>(environment.baseHref + '/productOrder/component/updateOnUnitQuantityChange', componentAssingedToMeDetails).pipe(
      catchError(this.globalService.handleError))
  }

  onImageColorWayUpload(data: FormData) {
    return this.http.post<any[]>(environment.baseHref + '/productOrder/colorWay/onColorWayImageUpload', data).pipe(
      catchError(this.globalService.handleError))
  }

  deleteColorwayPhoto(colorWay: any) {
    return this.http.post<any[]>(environment.baseHref + '/productOrder/colorWay/onColorWayImageDelete', colorWay).pipe(
      catchError(this.globalService.handleError))
  }

  // addUpdateFactoryProductionDate(arg0: any) {
  //   return this.http.post<any[]>(environment.baseHref + '/productOrder/addUpdateFactoryProductionDate', arg0).pipe(
  //     catchError(this.globalService.handleError))
  // }

  addUpdateFactoryPreferenceForPo(arg0: { productOrderId: any; isAgent: Boolean; isWetProcessingUnit: Boolean; declarationCheck?: Boolean }) {
    return this.http.post<any[]>(environment.baseHref + '/productOrder/addUpdateFactoryPreferenceForPo', arg0).pipe(
      catchError(this.globalService.handleError))
  }



  getProductOrderFactoryAssignmentDetails(productOrderId: number) {
    return this.http.get<any[]>(environment.baseHref + `/productOrder/getProductOrderFactoryAssignmentDetails/${productOrderId}`).pipe(
      catchError(this.globalService.handleError))
  }


  searchProductOrderByFactory(data: any) {
    return this.http.post<any[]>(environment.baseHref + `/productOrder/searchProductOrderByFactory`, data).pipe(
      catchError(this.globalService.handleError))
  }

  uploadProductOrderRequiredDocument(formData: FormData) {
    return this.http.post<any[]>(environment.baseHref + `/productOrder/uploadProductOrderRequiredDocument`, formData).pipe(
      catchError(this.globalService.handleError))
  }

  uploadedProductOrderRequiredDocumentList(productOrderId: any) {
    return this.http.get<any[]>(environment.baseHref + `/productOrder/uploadedProductOrderRequiredDocumentList/${productOrderId}`).pipe(
      catchError(this.globalService.handleError))
  }


  deleteOtherSectionDocument(id: any) {
    return this.http.delete<any[]>(environment.baseHref + `/productOrder/deleteProductOrderRequiredDocument/${id}`).pipe(
      catchError(this.globalService.handleError))
  }



  getWaterUse(productId: any, colorId: any, filters: any, groupBy: any) {
    //return this.http.get<any[]>(environment.baseHref + '/mta/getMyLevelOneSuppliers');
    let url = `${environment.baseHref}/productOrder/getWaterUse/${productId}/${colorId}/${encodeURIComponent(filters)}/${groupBy}`
    return this.http.get<any[]>(url).pipe(
      catchError(this.globalService.handleError))
  }
  getEnergyUse(productId: any, colorId: any, filters: any, groupBy: any) {
    //return this.http.get<any[]>(environment.baseHref + '/mta/getMyLevelOneSuppliers');
    let url = `${environment.baseHref}/productOrder/getEnergyUse/${productId}/${colorId}/${encodeURIComponent(filters)}/${groupBy}`
    return this.http.get<any[]>(url).pipe(
      catchError(this.globalService.handleError))
  }

  getEnergyDocs(productId: any, colorId: any, filters: any) {
    let url = `${environment.baseHref}/productOrder/getEnergyDocs/${productId}/${colorId}/${encodeURIComponent(filters)}`
    return this.http.get<any[]>(url).pipe(
      catchError(this.globalService.handleError))
  }

  getWaterDocs(productId: any, colorId: any, filters: any) {
    let url = `${environment.baseHref}/productOrder/getWaterDocs/${productId}/${colorId}/${encodeURIComponent(filters)}`
    return this.http.get<any[]>(url).pipe(
      catchError(this.globalService.handleError))
  }

  componentHandOverUpdate(components) {
    return this.http.post<any[]>(environment.baseHref + '/productOrder/component/handOverUpdate', components).pipe(
      catchError(this.globalService.handleError))
  }

  deleteComponent(subComp: any) {
    return this.http.post<any[]>(environment.baseHref + '/productOrder/component/delete', subComp).pipe(
      catchError(this.globalService.handleError))
  }

  deleteColorWay(subComp: any) {
    return this.http.post<any[]>(environment.baseHref + '/productOrder/colorway/delete', subComp).pipe(
      catchError(this.globalService.handleError))
  }


  getPoCompletionDetailedOverview(poId: any) {
    return this.http.get<any>(environment.baseHref + `/productOrder/completion/detailedOverview/${poId}`).pipe(
      catchError(this.globalService.handleError))
  }

  getProductionAndPoComponentOverview(productOrderId: any) {
    return this.http.get<any>(environment.baseHref + `/productOrder/getProductionAndPoComponentOverview/${productOrderId}`).pipe(
      catchError(this.globalService.handleError))
  }


  onUploadPoDocument(data: FormData) {
    return this.http.post<any[]>(environment.baseHref + '/productOrder/onUploadPoDocument', data).pipe(
      catchError(this.globalService.handleError))
  }

  onPoDocumentDelete(colorWay: any) {
    return this.http.post<any[]>(environment.baseHref + '/productOrder/onPoDocumentDelete', colorWay).pipe(
      catchError(this.globalService.handleError))
  }

  deleteAssingnement(assignment: any) {
    return this.http.delete<any[]>(environment.baseHref + '/productOrder/assignment/delete', assignment).pipe(
      catchError(this.globalService.handleError))
  }



  addUpdateFactoryTypePreference(data: any) {
    return this.http.post<any[]>(environment.baseHref + '/productOrder/phase2/addUpdateFactoryTypePreference', data).pipe(
      catchError(this.globalService.handleError))
  }
  getComponentUnitAndQuantity(productOrderId: any, factoryType) {
    return this.http.get<any[]>(environment.baseHref + `/productOrder/phase2/getComponentUnitAndQuantity/${productOrderId}/${factoryType}`).pipe(
      catchError(this.globalService.handleError))
  }

  private cache$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  getOrganizationFactoryUnitInfo(id, forceFetch: boolean): Observable<any> {
    if (!forceFetch && this.cache$.value) {
      return of(JSON.parse(JSON.stringify(this.cache$.value)));
    } else {
      return this.http.get<any>(`${environment.baseHref}/productOrder/phase2/getOrganizationFactoryUnitInfo/${id}`).pipe(
        tap(data => {
          data.
            forEach(fu => {
              fu.factoryTypeName = factoryTypeNamesArray[fu.factoryType - 1];
            });
          this.cache$.next(data)
        }),
        catchError(this.globalService.handleError)
      );
    }
  }
  saveOrganizationFactoryUnitInfo(data: any) {
    return this.http.post<any>(environment.baseHref + '/productOrder/phase2/saveOrganizationFactoryUnitInfo', data).pipe(
      catchError(this.globalService.handleError))
  }
  saveOrganizationApplicableFactoryUnits(data: any) {
    return this.http.post<any>(environment.baseHref + '/productOrder/phase2/saveOrganizationApplicableFactoryUnits', data).pipe(
      catchError(this.globalService.handleError))
  }

  onFactoryUnitDelete(factoryUnit: any) {
    return this.http.post<any>(environment.baseHref + '/productOrder/phase2/onFactoryUnitDelete', factoryUnit).pipe(
      catchError(this.globalService.handleError))
  }


  attachUnAttachProductOrderDocument(data) {
    return this.http.post<any>(environment.baseHref + '/productOrder/phase2/attachUnAttachProductOrderDocument', data).pipe(
      catchError(this.globalService.handleError))
  }

  getAttachedProductOrderDocument(productOrderId) {
    return this.http.get<any>(environment.baseHref + `/productOrder/phase2/getAttachedProductOrderDocument/${productOrderId}`).pipe(
      catchError(this.globalService.handleError))
  }

  onAddRemoveProductOrderApplicableFactoryType(data) {
    return this.http.post<any>(environment.baseHref + '/productOrder/phase2/onAddRemoveProductOrderApplicableFactoryType', data).pipe(
      catchError(this.globalService.handleError))
  }

  onFactoryTypeChemicalDataApplicationChange(data: any) {
    return this.http.post<any>(environment.baseHref + '/productOrder/phase2/onFactoryTypeChemicalDataApplicationChange', data).pipe(
      catchError(this.globalService.handleError))
  }

  getProductOrderCompletionDetailedOverviewNew(productOrderId: any) {
    return this.http.get<any>(environment.baseHref + `/productOrder/phase2/completion/detailedOverview/${productOrderId}`).pipe(
      catchError(this.globalService.handleError))
  }

  getFactoryUnitInfoBySupplierId(id): Observable<any> {
    return this.http.get<any>(`${environment.baseHref}/productOrder/phase2/getOrganizationFactoryUnitInfo/${id}`)
  }


  getMyExternalSupplyChainPartners(id): Observable<any> {
    return this.http.get<any>(`${environment.baseHref}/productOrder/phase2/externalSupplyChainPartners/get/${id}`)
  }

  createUpdateExternalSupplyChainPartners(data: any) {
    return this.http.post<any>(`${environment.baseHref}/productOrder/phase2/externalSupplyChainPartners/createUpdate`, data)
  }

  getPoExternalSuppliersList(productOrderId): Observable<any> {
    return this.http.get<any>(`${environment.baseHref}/productOrder/phase2/externalSupplyChainPartners/list/${productOrderId}`)
  }

  getExternalSupplierDetailsWithDocument(productOrderExternalSupplierId, productOrderId): Observable<any> {
    return this.http.get<any>(`${environment.baseHref}/productOrder/phase2/externalSupplyChainPartners/getDetailsWithDocument/${productOrderExternalSupplierId}/${productOrderId}`)
  }


  getAssignedComponentMaterialNames(productOrderId, externalSupplierId): Observable<any> {
    return this.http.get<any>(`${environment.baseHref}/productOrder/phase2/getAssignedComponentMaterialNames/${productOrderId}/${externalSupplierId}`)
  }


  saveTransactionCertificate(data) {
    return this.http.post<any>(`${environment.baseHref}/productOrder/phase2/saveTransactionCertificate`, data)
  }

  getTransactionCertificateDetails(documentId: any) {
    return this.http.get<any>(`${environment.baseHref}/productOrder/phase2/getTransactionCertificateDetails/${documentId}`)
  }


  saveTransactionCertificateProduct(data) {
    return this.http.post<any>(`${environment.baseHref}/productOrder/phase2/saveTransactionCertificateProduct`, data)
  }

  onDeleteCertProduct(id) {
    return this.http.delete<any>(`${environment.baseHref}/productOrder/phase2/deleteCertProduct/${id}`)
  }


  getProductOrderUsedFactoryTypesDetail(productOrderId): Observable<any> {
    return this.http.get<any>(`${environment.baseHref}/productOrder/phase2/getProductOrderUsedFactoryTypesDetail/${productOrderId}`)
  }

  getExternalSupplierAssignedComponentMaterialNames(productOrderId): Observable<any> {
    return this.http.get<any>(`${environment.baseHref}/productOrder/phase2/getExternalSupplierAssignedComponentMaterialNames/${productOrderId}`)
  }

}
 