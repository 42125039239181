<div class="gridster-item-content"
  *ngIf="visualCode !== VisualTypes.Gauge && visualCode !== VisualTypes.Table && visualCode !== VisualTypes.NumberCard && visualCode !== VisualTypes.WorldMap"
  id="{{ data['Name'] }}" [ngStyle]="{ width: width + 'px', height: height + 'px' }">
  <bhive-loading *ngIf="isLoadingChart" [width]="width" [height]="height"></bhive-loading>
  <div class="gridster-item-content" *ngIf="!isLoadingChart && !isEmptyChart(chartData, 'chart')"
    [ngStyle]="{ width: width + 'px', height: height + 'px' }">
    <button matTooltip="Drill up" type="button" *ngIf="showDrilUp" class="drillUp-button" (click)="drillUp()">
      <mat-icon>arrow_upward</mat-icon>
    </button>
    <canvas baseChart height="350" [datasets]="chartData" [labels]="chartLabels" [options]="chartOptions"
      [legend]="chartLegend" [type]="chartType" (chartClick)="chartClicked($event)"
      style="margin-left: 2vw; z-index: 1; padding-bottom: 3%;" #myChart>
    </canvas>
    <div [attr.id]="'tooltip_' + data['Name'] + data['VisualType']" class="chart-tooltip"></div>

  </div>
  <div *ngIf="!isLoadingChart && isEmptyChart(chartData, 'chart')" class="no-data">It looks like there is no data here,
    yet.</div>

</div>

<div class="gridster-item-content" *ngIf=" visualCode == VisualTypes.Gauge" id="{{ data['Name'] }}"
  [ngStyle]="{ width: width + 'px', height: height + 'px', 'padding-top': '10px' }">
  <canvas baseChart [data]="gaugeChartData" [options]="gaugeChartOptions" [plugins]="gaugeChartText"
    style="margin-left: 2vw" [type]="chartType" (chartClick)="chartClicked($event)" id="gaugeCanvas"></canvas>

  <bhive-loading *ngIf="isLoadingChart" [width]="width" [height]="height"></bhive-loading>

</div>
<!-- <div class="gridster-item-content" *ngIf=" !isLoadingChart && isEmptyChart(gaugeChartData, VisualTypes.Gauge) && visualCode == VisualTypes.Gauge"
  id="{{ data['Name'] }}" [ngStyle]="{ width: width + 'px', height: height + 'px', 'padding-top': '10px' }"  class="no-data" >
  <bhive-loading *ngIf="isLoadingChart" [width]="width" [height]="height" ></bhive-loading>
  It looks like there is no data here, yet.</div> -->


<div class="gridster-item-content map" *ngIf="visualCode == VisualTypes.WorldMap">
  <div id="map-container" #worldMap>
    <div id="tooltip" class="disable-hover"></div>
    <bhive-loading *ngIf="isLoadingChart" [width]="width" [height]="height"></bhive-loading>
  </div>
</div>

<div class="item-table" *ngIf="visualCode == VisualTypes.Table" [ngStyle]="{ height: height + 'px' }">
  <div class="item-table" [ngStyle]="{ height: height + 'px' }">
    <div style="overflow-x: scroll" [ngStyle]="{ maxHeight: height! - 70 + 'px' }">
      <table mat-table *ngIf="!data.Name.includes('ChemIQ')" [dataSource]="tableDatasource" #tableMatSort="matSort"
        matSort cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">
        <ng-container *ngFor="let tColumn of tableColumns">
            <ng-container *ngIf="tColumn.name !== 'comment'" matColumnDef="{{ tColumn.name }}">
                <th mat-header-cell *matHeaderCellDef cdkDrag matTooltip="{{ tColumn.description }}">
                    {{ tColumn.name }}
              <lib-table-filter-headers [columnName]="tColumn.name"
                [dataSource]="tableDatasource"></lib-table-filter-headers>
                </th>
                <td mat-cell *matCellDef="let object" [class.clickable]="addClickableClass(object, tColumn.key)"
                (click)="isClicked(object, tColumn.key) && onColumnClick(object, tColumn.key)">
                      <ng-container *ngIf="this.data.Name === 'CAS Screener Report'; else normalFormat">
                        {{ object[tColumn.name]}}
                      </ng-container>

                      <ng-template #normalFormat>
                        {{ object[tColumn.name]| conditionalNumberFormat }}
                      </ng-template>
                </td>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="isCommentColumnPresent" #commentColumn matColumnDef="comment">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Comment
          </th>
          <td mat-cell *matCellDef="let formula">
            <button (click)="openDialog(formula.comment)">
              <mat-icon class="text-sm" [ngStyle]="{ color: formula.hasComment ? '#05AFF0' : '' }"
                svgIcon="mat_solid:comment"></mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="tableColumnsNames; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: tableColumnsNames"></tr>
      </table>
      <table mat-table *ngIf="data.Name.includes('ChemIQ')" [dataSource]="tableDatasource" #tableMatSort="matSort"
        matSort cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">
        <!-- Group Header Row -->
        <ng-container matColumnDef="ffCId">
          <th mat-header-cell *matHeaderCellDef colspan="1" style="border: 1px solid #bdd0e9; text-align: center;"> FFC ID </th>
        </ng-container>

        <ng-container matColumnDef="step1Group">
          <th mat-header-cell *matHeaderCellDef colspan="6" class="centered" style="border: 1px solid #bdd0e9; text-align: center;"> STEP 1 </th>
        </ng-container>

        <ng-container matColumnDef="step2Group">
          <th mat-header-cell *matHeaderCellDef colspan="4" class="centered" style="border: 1px solid #bdd0e9; text-align: center;"> STEP 2 </th>
        </ng-container>
        
        <ng-container *ngFor="let tColumn of tableColumns">
          <ng-container *ngIf="tColumn.name !== 'comment'" matColumnDef="{{ tColumn.name }}">
            <th mat-header-cell *matHeaderCellDef cdkDrag matTooltip="{{ tColumn.description }}">
              {{ tColumn.name }}
              <lib-table-filter-headers [columnName]="tColumn.name"
                [dataSource]="tableDatasource"></lib-table-filter-headers>
            </th>
            <td mat-cell *matCellDef="let object" [class.clickable]="addClickableClass(object, tColumn.key)"
              (click)="isClicked(object, tColumn.key) && onColumnClick(object, tColumn.key)">
              {{ object[tColumn.name] | conditionalNumberFormat }}
            </td>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="isCommentColumnPresent" #commentColumn matColumnDef="comment">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Comment
          </th>
          <td mat-cell *matCellDef="let formula">
            <button (click)="openDialog(formula.comment)">
              <mat-icon class="text-sm" [ngStyle]="{ color: formula.hasComment ? '#05AFF0' : '' }"
                svgIcon="mat_solid:comment"></mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="['ffCId','step1Group','step2Group']"></tr>
        <tr mat-header-row *matHeaderRowDef="tableColumnsNames; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: tableColumnsNames"></tr>
      </table>
    </div>
    <mat-paginator #tableMatPaginator="matPaginator" [pageSizeOptions]="[10, 20, 50]"
      showFirstLastButtons></mat-paginator>
  </div>
  <div *ngIf="!isLoadingChart && isEmptyChart(tableDatasource.data, VisualTypes.Table)" class="no-data">It looks like
    there is no data here, yet.</div>
  <bhive-loading *ngIf="isLoadingChart" [width]="width" [height]="height"></bhive-loading>

</div>

<div *ngIf="visualCode == VisualTypes.NumberCard" class="dashboard-count-chart"
  [ngStyle]="{ width: width! + 20 + 'px', height: height + 'px' }">
  <div class="dashboard-count-chart" *ngIf="!isLoadingChart &&  !isEmptyChart(mainValueCard, VisualTypes.NumberCard)"
    [ngStyle]="{ width: width! + 20 + 'px', height: height + 'px' }">
    <mat-card>
      <div class="info-cards">
        <mat-card-title [ngStyle]="{ 'font-size': calculateFontSize(width, height) }" (click)="chartClicked($event)"
          matTooltip="{{ descriptionCardTooltip }}">{{ mainValueCard | conditionalNumberFormat }}{{format == 'percent'?
          '%':''}}</mat-card-title>
      </div>
    </mat-card>
  </div>
  <div *ngIf="!isLoadingChart && isEmptyChart(mainValueCard, VisualTypes.NumberCard)" class="no-data">It looks like
    there is no data here, yet.</div>

  <bhive-loading *ngIf="isLoadingChart" [width]="width" [height]="height"></bhive-loading>
</div>